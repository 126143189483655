<template>
  <ContainerPage>
    <div>
      <h1>Gestion des candidats</h1>
      <div class="mb-3">
        Bienvenue sur l'interface de gestion des candidats. En prévision d'un entretien, vous devez créer un candidat
        via le forumlaire ci-dessous puis transmettre l'url au collaborateur. Ce dernier devra réaliser le
        questionnaire.
      </div>
      <div class="d-flex">
        <div class="w-50 p-3 shadow-sm rounded" v-background-3>
          <h6 class="mb-4">Ajouter un candidat</h6>
          <base-input class="mb-4" v-model="nom" inputText="Nom" :errors="feedback.nom"></base-input>
          <base-input class="mb-4" v-model="prenom" inputText="Prénom" :errors="feedback.prenom"></base-input>
          <base-input-area class="mb-4" v-model="commentaire" text="Commentaire"></base-input-area>
          <div class="d-flex w-100">
            <base-button class="ml-auto" text="Enregistrer" icon="save" @click="add" :loading="loading"></base-button>
          </div>
        </div>
        <div class="w-100 ml-3">
          <div v-for="entretien in entretiens" :key="entretien.id">
            <container-list-extend title="">
              <template v-slot:title>
                <div class="w-100 p-3">{{ entretien.nom }} {{ entretien.prenom }}</div>
                <div>{{ $moment(entretien.date).format("DD/MM/YYYY") }}</div>
                <div class="ml-3 mr-1">
                  <span v-if="!entretien.complete" class="btn text-primary text-nowrap" @click="copyUrl(entretien.url)"
                    >Copier le lien</span
                  >
                  <span v-else class="text-success">Réalisé</span>
                </div>
              </template>
              <template v-slot:form>
                <div class="p-3">
                  <div>Nom : {{ entretien.nom }}</div>
                  <div>Prénom : {{ entretien.prenom }}</div>
                  <div>Date : {{ $moment(entretien.date).format("DD/MM/YYYY") }}</div>
                  <div>Url : https://arkadia-qwark.fr/qualification/{{ entretien.url }}</div>
                  <div>Commentaire : {{ entretien.comment }}</div>
                  <div>Score : {{ entretien.score }} / 5</div>
                </div>
              </template>
            </container-list-extend>
          </div>
        </div>
      </div>
    </div>
  </ContainerPage>
</template>
<script>
import ContainerPage from "@/components/containers/ContainerPage.vue";
import BaseInput from "@/components/bases/Input.vue";
import BaseButton from "@/components/bases/Button.vue";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";

export default {
  name: "Candidats",
  components: { ContainerPage, BaseInput, BaseButton, ContainerListExtend, BaseInputArea },
  data() {
    return {
      entretiens: [],
      nom: "",
      prenom: "",
      commentaire: "",
      feedback: {},
      loading: false,
    };
  },
  mounted() {
    this.all();
  },
  methods: {
    all() {
      this.$http.get("/candidats").then((resp) => (this.entretiens = resp.data));
    },
    add() {
      this.$http
        .post("/qualification/url", {
          nom: this.nom,
          prenom: this.prenom,
          comment: this.commentaire,
        })
        .then((resp) => {
          this.entretiens.push(resp.data);
          this.nom = "";
          this.prenom = "";
        });
    },
    copyUrl(uuid) {
      navigator.clipboard.writeText("https://arkadia-qwark.fr/qualification/" + uuid).then(
        function () {
          alert("L'URL a été copiée dans votre presse papier");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
<style></style>
